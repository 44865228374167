import axios from "axios";
import connectionMixin from "@/mixins/connectionParams.js";

export default {
  mixins: [connectionMixin],
  data() {
    return {
      deletingFunctionName: [],
      runFunctionName: "",
      deleteDialog: false,
      runDialog: false,
      runResponseDialog: "",
      successMessage: false,
      successMessageText: "",
      errorMessage: false,
      errorMessageText: "",
      enableCreateButton: true,
      search: "",
      selected: [],
      functionList: [],
      triggerList: [],
      functionsWithTrigger: [],
    };
  },
  created() {
    this.getFunctionsList();
  },
  computed: {
    headersTable() {
      return [
        {
          text: this.$t("message.functionlist.table.column.name").toUpperCase(),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "functions",
        },
      ];
    },
    functionItems() {
      return this.functionsWithTrigger;
    },
  },

  methods: {
    redirectToCreateNew() {
      this.$router.push({ name: "Choose a new language for the function" });
    },

    redirectToFunctionDetails(func) {
      this.$router.push({
        name: "Function details",
        params: { id: func.name },
      });
    },
    //Loads the Functions list
    getFunctionsList() {
      axios
        .get(this.apihost + "/functions", this.opts)
        .then((response) => {
          this.functionList = response.data;
          this.getTriggerList();
        })
        .catch((err) => {
          console.error("failed to retrieve function list", err);
          if (err.response.status == 403) {
            this.enableCreateButton = false;
          }
        });
    },
    //Run the function
    invokeFunction(name) {
      axios
        .post(this.apihost + "/functions/" + name + "/runs", {}, this.opts)
        .then((res) => {
          this.successMessageText = this.$t(
            "message.functionlist.snackbar.function_started"
          );
          this.successMessage = true;
          this.runResponseDialog = res.data.result;
          this.runDialog = true;
        })
        .catch((err) => {
          this.errorMessageText =
            "Error while invoking function with status code " + err.statusCode;
          this.errorMessage = true;
        });
      this.runFunctionName = name;
    },
    //Open the delete function dialog
    deleteFunctionDialog(names) {
      this.deleteDialog = true;
      this.deletingFunctionName = names;
    },
    //Delete Functions in Serverless environment
    deleteFunction(name) {
      return axios
        .delete(this.apihost + "/functions/" + name, this.opts)
        .then(() => {
          this.successMessage = true;
          this.successMessageText = this.$t(
            "message.functionlist.snackbar.function_deleted",
            { name: name }
          );
          this.deleteDialog = false;
          this.refresh_table();
        })
        .then(() => {
          if (this.selected.length == 0) {
            this.change_functions_btns_status("none", true);
          }
        });
    },
    deleteItems(names) {
      if (!Array.isArray(names)) {
        this.deleteFunction(names);
      } else if (names.length > 1) {
        for (let index = 0; index < names.length; index++) {
          const name = names[index];
          this.deleteFunction(name);
        }
      } else {
        const name = names[0];
        this.deleteFunction(name);
      }
      this.selected = [];
    },

    //Delete selected functions
    delete_all_selected() {
      try {
        let selectedItems = this.selected;
        let names = [selectedItems[0].name];
        for (let index = 1; index <= selectedItems.length - 1; ++index) {
          names.push(selectedItems[index].name);
        }
        this.deleteFunctionDialog(names);
      } catch (e) {
        console.log("error", e);
        return null;
      }
    },
    //Change the Function toolbar icons status:
    //Show/Hide the Delete icon
    //Sets the name of Select icon
    change_functions_btns_status(sel, del) {
      this.functionsStatus.selectBtnIcon = sel;
      this.functionsStatus.deleteBtnHide = del;
    },
    //Select the checkbox for all rows
    // and sets the name of Select Icon in the Functions toolbar
    select_all_rows() {
      if (this.$refs.table_functions.everyItem === false) {
        this.$refs.table_functions.toggleSelectAll(true);
        this.change_functions_btns_status("full", false);
        return "full";
      } else {
        this.$refs.table_functions.toggleSelectAll(false);
        this.change_functions_btns_status("none", true);
        return "none";
      }
    },
    // Show Delete icon if the checkbox in any row is selected
    // and sets the name of Select Icon in the Functions toolbar
    select_row() {
      if (this.$refs.table_functions.someItems === true) {
        this.change_functions_btns_status("some", false);
      } else {
        this.change_functions_btns_status("none", true);
      }
    },
    //Refresh functions table
    refresh_table() {
      this.getFunctionsList();
    },
    //Create the trigger for function
    createTrigger(functionName) {
      axios
        .post(
          this.apihost + "/triggers",
          {
            functionName: functionName,
          },
          {
            headers: {
              Authorization: "Bearer " + this.tokenKey,
            },
          }
        )
        .then((response) => {
          let status = response.status;
          if (Math.floor(status / 100) === 2) {
            this.successMessage = true;
            this.successMessageText = this.$t(
              "message.functionlist.snackbar.function_associated",
              { name: functionName }
            );
          }
          this.refresh_table();
        })
        .catch((err) => {
          this.handlingError(err.response.status, functionName);
        });
    },
    //Obtains the Triggers list
    getTriggerList() {
      axios
        .get(this.apihost + "/triggers/", this.opts)
        .then((listOfTrigger) => {
          this.triggerList = listOfTrigger.data;
          this.createFunctionsWithTrigger(this.triggerList);
        });
    },
    //Create a copy of functions list adding the trigger if exist
    createFunctionsWithTrigger(trList) {
      this.functionsWithTrigger = this.functionList;
      if (trList) {
        trList.forEach((trigger) => {
          this.functionList.forEach((func, i) => {
            if (trigger.functionName == func.name) {
              this.functionsWithTrigger[i].triggerName = trigger.name;
            }
          });
        });
      }
    },
  },
};
